import * as React from "react"
import { Fragment } from 'react'
import { Link, graphql } from "gatsby"
import { Popover, Transition } from '@headlessui/react'
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const HomePage = ({ data }) => {
  const entry = data.prismicHomePage
  if (!entry) return null
  return (
    <Popover className="w-full h-screen bg-light-grey relative">     
    {({ open }) => ( 
    <div className="w-full container mx-auto h-full">
      <Transition
      show={open}
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-200 ease-out"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      >
      <Popover.Panel focus static className="fixed h-full z-50 top-0 inset-x-0 transition">
        <div className="w-full h-full mx-auto relative">

          <div className="absolute inset-0 w-full bg-dark-grey"></div>

          <div className="w-full pt-12 relative">
            <div className="w-10/12 mx-auto max-w-screen-xl">
              <div class="flex justify-between relative">
                <Link to="/" className="focus:outline-none"><StaticImage placeholder="none" src="../images/logo_reversed_without_tag.png" alt="dallers" className="w-52 focus:outline-none" style={{ display: "block" }} imgStyle={{ objectFit: "contain" }} /></Link>
                <Popover.Button className="absolute right-0 top-0 focus:outline-none">
                  <span className="sr-only">Close menu</span>
                  <div className="bg-orange text-white p-6 rounded-full flex items-center justify-center relative">
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                </Popover.Button>
              </div>
            </div>
          </div>
          
          <div className="w-full pt-24 sm:pt-36 relative">
            <div className="w-10/12 mx-auto max-w-screen-xl">
              <div class="flex-none sm:flex gap-12 justify-between">
                <div className="w-full sm:w-6/12 space-y-6">
                  <div>
                    <Link to="/about/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      About
                    </Link>
                  </div>
                  <div>
                    <Link to="/founder/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      Founder
                    </Link>
                  </div>
                  <div>
                    <Link to="/values/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      Values
                    </Link>
                  </div>
                  <div>
                    <Link to="/services/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      Services
                    </Link>
                  </div>
                  <div>
                    <Link to="/contact/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      Contact
                    </Link>
                  </div>
                </div>
                <div className="w-full mt-12 sm:mt-0 sm:w-6/12 justify-center">
                  <div>
                    <p className="font-verdana text-lg text-mid-grey leading-normal mb-12 hidden sm:inline-block">Take a look at what we do here and drop us a line.</p>
                    <p className="font-verdana text-lg text-orange mb-3 font-bold"><a href="tel:+642102422951" title="+64 (0)21 024 22951">+64 (0)21 024 22951</a><br />
                    <a href="mailto:don@dallersconsult.com" title="don@dallersconsult.com" rel="nofollow noopener">don@dallersconsult.com</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>

    <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/" />

    <div className="w-full">
      <div className="w-10/12 mx-auto max-w-screen-xl relative">
        <Popover.Button className="absolute fixed right-0 top-12 focus:outline-none">
          <span className="sr-only">Open menu</span>
          <div className="bg-white p-6 rounded-full flex items-center justify-center relative">
            <svg xmlns="http://www.w3.org/2000/svg" className="absolute h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </div>
        </Popover.Button>
        
      </div>
    </div>

    <div className="container mx-auto h-full flex justify-center items-center">
      <div className="w-10/12 mx-auto max-w-screen-xl">
        <StaticImage placeholder="none" src="../images/logo_with_tag.png" alt="dallers" style={{ width: "100%", display: "block" }} imgStyle={{ objectFit: "contain" }} />
      </div>
    </div>

    </div>
  )}
  </Popover>
  )
}

export const query = graphql`
query {
  prismicHomePage {
    data {
      page_title
      meta_description
    }
  }
}
`

export default HomePage
